@font-face {
    font-family: 'cera_prolight';
    src: url('../font/cerapro-light-webfont.woff2') format('woff2'),
        url('../font/cerapro-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'cera_promedium';
    src: url('../font/cerapro-medium-webfont.woff2') format('woff2'),
        url('../font/cerapro-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

html {
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: thin;
    font-family: "cera_prolight" !important;
}

body {
    background: #E5E5E5 !important;
    height: 100vh;
}

.product-rider-wrapper {
    width: 100%;
    position: absolute;
}

.product-rider {
    color: #FFFFFF;
    text-align: center;
    font-size: 30px;
    max-width: 660px;
    margin: 67px auto;
    font-weight: 900;
    font-family: "cera_promedium";
}

.home-wrapper {
    position: absolute;
    z-index: 0;
    width: 100%;
}

.top-divider {
    width: 100%;
    min-height: 400px;
    height: calc(50vh - 70px);
    background: linear-gradient(105.31deg, #00425F 17.87%, #2193C5 100%);
}

.bottom-divider {
    width: 100%;
    height: calc(50vh - 70px);
}

html::-webkit-scrollbar {
    width: 5px;
    height: 8px;
}

.home-container {
    width: 100%
}

html::-webkit-scrollbar-thumb {
    background-color: #00425F;
}

.container {
    width: 100%;
}

.body {
    position: absolute;
    width: 100%;
    height: calc(100% - 74px);
    background: linear-gradient(117.11deg, #00425F 17.87%, #2193C5 100%);
}

.home-bg {
    background: #E5E5E5;
}

.body-container {
    margin: 0 auto;
    position: relative;
}

/* Styles for the table components */
.table td,
.table th {
    padding: 1rem .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.status-btn {
    width: 5.125rem;
    text-align: center;
    border-radius: 1rem;
    font-weight: 600;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.info-header {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #00425F;
}

.info-body {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-top: 2em;
    color: #00425F;
}

.product-container {
    min-height: 500px;
    background-color: #FFFFFF;
    border-radius: 12px;
    margin: 0 auto;
}

.product-greetings {
    color: #00425F;
    font-weight: 900;
    font-size: 20px;
    font-family: "cera_promedium";
}

.module-error {
    font-family: 'cera_prolight';
    color: #353F50;
    background: rgba(237, 52, 43, 0.1);
    border: 1px solid #ED342B;
    padding: 12px 24px;
    border-radius: 6px;
}

@media only screen and (min-width: 1200px) {
    .body {
        min-width: 1200px;
        min-height: 914px;
        left: 0px;
        top: 74px;
    }

    .homepage-header {
        padding-top: 3rem;
        padding-bottom: 2rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .product-container {
        max-width: 1400px;
    }

    .login-banner {
        position: absolute;
        width: 467px;
        height: 381px;
        left: 0px;
        top: 188px;
        background-image: url('../images/login-image.png');
        background-size: cover;
        box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
    }

    .body-container {
        width: 1150px;
    }



    .product-title {
        font-family: 'cera_promedium';
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        color: #00425F;
    }

    .product-description {
        font-family: 'cera_promedium';
        font-weight: 500;
        font-size: 13px;
        padding: 0;
        line-height: 24px;
        color: #5F738C;
    }

    .product-link {
        font-family: 'cera_promedium';
        font-size: 14px;
        color: #0670F5;
    }

    .login-info {
        position: absolute;
        width: 960px;
        height: 454px;
        left: 189px;
        top: 150px;
        background: #EEF1F7;
        border-radius: 4px;
    }

    .login-panel {
        position: absolute;
        width: 374px;
        height: 573px;
        left: 745px;
        top: 114px;
        padding: 43px 36px;
        background: #FFFFFF;
        /* border: 0.2px solid #5F738C; */
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .info-div {
        position: absolute;
        top: 130px;
        left: 308px;
        width: 221px;
        min-height: 100px;
    }

}

@media only screen and (min-width: 800px) and (max-width: 1199px) {
    .body-container {
        width: 800px;
    }

    .homepage-header {
        padding-top: 3rem;
        padding-bottom: 2rem;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    .product-container {
        max-width: 1000px;
    }

    .product-greetings {
        color: #00425F;
        font-size: 20px;
        font-family: 'cera_promedium';
    }

    .product-title {
        font-family: 'cera_promedium';
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        color: #00425F;
    }

    .product-description {
        font-family: 'cera_promedium';
        font-weight: 500;
        font-size: 13px;
        padding: 0;
        line-height: 24px;
        color: #5F738C;
    }

    .product-link {
        font-family: 'cera_promedium';
        font-size: 14px;
        color: #0670F5;
    }

    .body {
        min-width: 800px;
        min-height: 800px;
        left: 0px;
        top: 74px;
    }

    .login-info {
        position: absolute;
        width: 700px;
        height: 454px;
        left: 50px;
        top: 145px;
        background: #EEF1F7;
        border-radius: 4px;
    }

    .login-panel {
        position: absolute;
        width: 374px;
        height: 573px;
        left: 345px;
        top: 109px;
        padding: 43px 36px;
        background: #FFFFFF;
        /* border: 0.2px solid #5F738C; */
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .info-div {
        position: absolute;
        top: 130px;
        left: 48px;
        width: 221px;
        min-height: 100px;
    }
}

@media only screen and (min-width: 370px) and (max-width: 799px) {
    .body-container {
        width: 370px;
    }

    .product-container {
        max-width: 700px;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .homepage-header {
        padding-top: 3rem;
        padding-bottom: 2rem;
    }

    .module-error {
        margin-top: 16px;
    }

    .product-greetings {
        color: #00425F;
        font-size: 20px;
        font-family: 'cera_promedium';
    }

    .product-title {
        font-family: 'cera_promedium';
        font-size: 16px;
        line-height: 20px;
        margin: 10px 16px 0 0;
        color: #00425F;
    }

    .product-description {
        font-family: 'cera_promedium';
        margin-top: 8px;
        font-weight: 500;
        font-size: 13px;
        padding: 0;
        line-height: 24px;
        color: #5F738C;
    }

    .product-link {
        font-family: 'cera_promedium';
        font-size: 14px;
        color: #0670F5;
        margin-top: 8px;
    }

    .product-rider {
        font-size: 20px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .body {
        min-width: 370px;
        min-height: 870px;
        left: 0px;
        top: 65px;
    }

    .login-panel {
        position: absolute;
        width: 370px;
        height: 573px;
        left: 0px;
        top: 79px;
        padding: 43px 36px;
        background: #FFFFFF;
        /* border: 0.2px solid #5F738C; */
        box-sizing: border-box;
        box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }

    .info-div {
        position: absolute;
        top: 130px;
        left: 48px;
        width: 221px;
        min-height: 100px;
    }
}

@media only screen and (max-width: 369px) {
    .product-container {
        max-width: 340px;
    }

    .product-container img {
        max-width: 300px;
        margin-left: 20px;
    }

    .product-greetings {
        color: #00425F;
        padding-top: 2.5rem;
        padding-left: 16px;
        font-family: 'cera_promedium';
        font-size: 20px;
    }

    .product-title {
        font-family: 'cera_promedium';
        font-size: 16px;
        line-height: 20px;
        margin: 10px 16px 0 16px;
        color: #00425F;
    }

    .product-description {
        font-family: 'cera_promedium';
        margin: 0 16px;
        font-weight: 500;
        font-size: 13px;
        padding: 0;
        line-height: 24px;
        color: #5F738C;
    }

    .product-link {
        font-family: 'cera_promedium';
        font-size: 14px;
        color: #0670F5;
        margin: 0 16px 36px;
    }

    .product-rider {
        font-size: 20px;
    }
}

.navbar {
    width: 100%;
    height: 4.625rem;
    z-index: 999000 !important;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.menu-bar {
    position: relative;
    /* top: 64px; */
    /* left: 50px; */
    width: calc(100vw - 250px);
    min-width: 900px;
    margin: -1rem 0 2rem 0;
    height: 64px;
    background: linear-gradient(179.34deg, #1A4996 -40.31%, #03435F 112.95%);
    padding: 10px 20px;
}

/* @media (min-width: 1200px) {
    .menu-bar {
        margin-left: -3rem;
    }
} */

/* @media (max-width: 991px) {
    .menu-bar {
        left: 0;
    }
} */

.menu-link {
    color: #ffffff;
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Source Sans Pro";
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 4px;

}

.rfid-menu-link {
    color: #ffffff;
    line-height: 18px;
    font-weight: 600;
    font-family: "Source Sans Pro";
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 4px;
    cursor: pointer;
}

.rfid-menu-link span.active {
    border-bottom: 3px solid #00425F;
}

.menu-link span {
    color: #ffffff;
    font-weight: 500;
    font-family: "Source Sans Pro";
}

.menu-link a {
    margin-right: 20px
}

.menu-link span.active {
    border-bottom: 3px solid #3897EB;
}

.menu-input-container {
    float: right;
    position: relative;
    top: -16px;
    height: 35px;
    width: 313px;
    background: rgba(139, 167, 213, 0.43);
    color: #ffffff;
    padding-left: 16px;
}

.menu-input {
    border: none;
    background: rgba(139, 167, 213, 0);
    height: 100%;
    color: #ffffff;
}

.input-icon {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
}

input[type="text"],
input[type="password"],
textarea,
select {
    outline: none;
    color: #ffffff;
}

.menu-bar ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1;
    /* Firefox */
}

.menu-bar :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff;
}

.menu-bar ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff;
}


.guest-footer {
    width: 100%;
    height: 56px;
    background: #00425F;
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 50px;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.footer-link {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 56px;
    margin-right: 63px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.footer-link:hover {
    color: #dddddd;
}

.registration-info {
    float: right;
    margin-top: 26px;
}

.registration-text {
    width: 154px;
    height: 23px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #FFFFFF;
}

.registration-btn {
    width: 93px;
    margin-left: 20px;
    padding: 10px 20px;
    height: 40px;
    background: #EEF1F7;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
}

.logo {
    /* position: absolute; */
    margin: -5px 0 5px;
    width: 186px;
    height: 64px;
    left: 7px;
    top: 0;
    background: url(../images/logo-transparent.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.new-logo{
    height: 35px;
    margin-left: 20px;
}

.username span {
    margin-right: 16px;
}

.username {
    position: absolute;
    right: 42px;
    /* font-style: normal; */
    /* font-weight: bold; */
    font-size: 14px;
    /* line-height: 20px; */
    color: #00425F;
}

@media only screen and (max-width: 730px) {
    .username {
        right: 20px;
    }
}

.login-title {
    height: 23px;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.01em;
    display: block;
    color: #000000;
}

.login-instruction {
    height: 23px;
    font-family: "Source Sans Pro";
    display: block;
    margin-top: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #000000;
}

.input-container {
    margin-top: 25px;
}

.display-block {
    display: block;
}

.input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    font-family: "Source Sans Pro";
    letter-spacing: 0.01em;
    color: #4B4B4B;
}

.account-input {
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-sizing: border-box;
    border-radius: 2px;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
}

.text-center {
    text-align: center;
}

.account-btn {
    background: #03435F;
    border-radius: 2px;
    height: 40px;
    border: none;
    width: 100%;
    color: #FFFFFF !important;
    font-family: "Source Sans Pro";
}

.account-btn:disabled{
    background-color: lightgrey;
    cursor:not-allowed;
}

.forgot-password-text {
    margin-top: 33px;
    margin-bottom: 22px;
    display: block;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-decoration-line: underline;
    color: #00425F;
}

.panel-footer-container {
    position: absolute;
    bottom: 28px;
    left: 0;
    width: 100%;
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */

    letter-spacing: 0.01em;
}

.panel-footer-link {
    color: #00425F;
    text-decoration: underline;
}

.error-container {
    background: rgba(255, 0, 0, 0.2);
    border-radius: 2px;
    margin-top: 18px;
    padding: 16px 21px;
}

.success-container {
    background: green;
    border-radius: 2px;
    margin-top: 18px;
    padding: 16px 21px;
}

.success-container span {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #fff;
}

.success-close {
    color: #fff;
    float: right;
    padding-left: 16px;
    cursor: pointer;
}

.error-close {
    color: #4B4B4B;
    float: right;
    padding-left: 16px;
    cursor: pointer;
}

.asterisk {
    color: #D21C1C;
    font-size: 24px;
    position: relative;
    margin-left: 5px;
    bottom: -5px;
}

.navbar-ag {
    /* width: 100%; */
    /* height: 4.625rem; */
    /* z-index: 999001 !important; */
    z-index: 700;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.ant-notification {
    z-index: 999002 !important;
}

#product-switch-menu {
    position: absolute;
    top: 65px;
    right: -35px;
    background-color: #ffffff;
    border-radius: 5px;
    border: thin solid #cccccc;
}

#product-switch-menu a {
    display: block;
    color: #444444;
    text-decoration: none;
}