.faq_container {
    text-align: center;
  
    h3 {
      margin: 1rem 0;
      font-weight: bold;
      font-size: 24px;
      color: #333;
    }
  }
  
  .question-section {
    background: transparent;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
  }
  
  .question-align {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  
  .question-style {
    font-size: 16px;
    font-weight: bold; /* Set h4 to bold weight */
    color: #333; /* Adjust color for lighter appearance */
  }
  
  .question-icon {
    background: none;
    border: none;
    color: #1b6aae;
    cursor: pointer;
    font-size: 22px;
  }
  
  .rotate {
    transform: rotate(45deg);
  }
  
  .answer-divider {
    border-top: 1px solid lightgray;
  }
  
  .answer {
    margin-top: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
  }

  
  .answer p {
    margin: 0;
    padding: 0.5rem 0; /* Set margin to 0 for <p> elements inside .answer */
    font-weight: bold; /* Set p to normal weight */
  color: #333; /* Adjust color for lighter appearance */
  font-size: 12px;
  }
  
  .answer li {
    list-style: square;
    font-size: 12px;
    padding: 0;
  }
  
  /* Improved responsiveness for smaller screens */
  @media (max-width: 600px) {
    .question-section {
      width: 90%;
    }
  }
  