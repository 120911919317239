.flex-center{
    display: flex;
}

/* Animation */
.vwrap, .head-caption {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
  }
  
  /* (B) OUTER CONTAINER */
  .vwrap {

    overflow: hidden; /* HIDE SCROLLBARS */
  }
  
  /* (C) MIDDLE CONTAINER */
  .vmove {
    position: relative;
    bottom: 0%;
  }

  @keyframes slidev {
    0% { bottom: 0; }
  25% { bottom: 0; }
  28% { bottom: 100%; }
  50% { bottom: 100%; }
  53% { bottom: 200%; }
  75% { bottom: 200%; }
  78% { bottom: 300%; }
  97% { bottom: 300%; }
  100% { right: 0; }
  }
  .vmove { animation: slidev linear 15s infinite; }
  .vmove:hover { animation-play-state: paused; }
/* Animation ends */
.landing-container h1, .landing-container h2,
.landing-container h3,.landing-container h5,.landing-container p,
.landing-container span, .landing-container label, .landing-container h4,
.landing-container legend, .landing-container li, .landing-container a, 
.lead-form-modal p, .lead-form-modal h1, .lead-form-modal label, .lead-form-modal input
.lead-form-modal a, .lead-form-modal h3{
    font-family: 'Averta', sans-serif;
    font-variant: normal;
    word-spacing: normal !important;
}

.d-desktop{
    display: none !important;
}

.landing-container{
 background-color: white;
 padding: 0px !important;
 font-variant: normal !important;
 font-feature-settings: normal !important;
 -webkit-font-feature-settings: normal !important;
}

.landing-page-container{
 background: #F9FAFB;
 font-family: 'Averta', sans-serif !important;
}

.landing-nav{
    z-index: 1000 !important;
    width: 100%;
    background-color: white;
    padding: 100px;
    padding-top: 14px;
    padding-bottom: 8px;
    position: sticky;
    border: none;
    box-shadow: none;
}

.navbar-brand{
    margin-right: 20%;
}

.navbar-nav{
    width: 100%;
    justify-content: space-between;
    margin-top: 3.5px;
    height: 52.5px;
    padding-left: 127px;
}

.sitemap-container{
    width: 100%;
    background-color: white;
    border-top: solid #F9fafb 3.5px;
    height: 52px;
    padding-left: 127px;
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    align-items: center;
}

.sitemap-link{
    color: #374151;
    font-size: 12px;
    font-family: 'Averta', sans-serif;
}
.sitemap-link.current{
    color: #9CA3AF;
    /* word-spacing: -2px; */
}

.hero-section{
 color: white;
 width: 100%;
 height: 669px;
 padding: 103px;
 padding-bottom: 130px;
 background: url(../../assets/images/new_hero.png) no-repeat;
 background-size: cover;
 display: flex;
 justify-content: center;
 align-items: flex-end;
 text-align: center;
}

.sub-hero{
    /* linear-gradient(0deg, rgba(0, 66, 95, 0.75), rgba(0, 66, 95, 0.75)), */
    width: 100%;
    height: 477px;
    text-align: center;
    background:  url(../../assets/images/forecort_hero.svg) no-repeat;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quick-energy.sub-hero{
    background-image: linear-gradient(0deg, rgba(0, 66, 95, 0.85), rgba(0, 66, 95, 0.85)), 
    url(../../assets/images/quickenergy_hero.png);
}

.contract-mgt.sub-hero{
    background-image: url(../../assets/images/contractmgt-banner.png);
}

.cap-section{
    /* max-width: 820px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}


.head-caption{
    font-family: 'Averta', sans-serif !important;
    font-size: 40px;
    font-weight: 800;
}

.sub-caption{
    font-family: 'Averta', sans-serif !important;
    font-size: 16px;
    margin-top: 10px !important;
    display: block;
    /* word-spacing: -4px; */
}

.hero-caption{
    font-size: 32px;
    font-weight: 800;
    font-family: 'Averta', sans-serif;
}

.forecourt-container{
    background-color: #F9FAFB;
}

.sub-hero-caption{
    font-weight: 400;
    font-size: 16px;
    font-family: 'Averta', sans-serif;
    color: #F9FAFB;
    /* word-spacing: -5px; */
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 0px;
    text-align: center;
}

.sub-hero-button{
    color: white !important;
    background-color: #479FC8;
    border: none !important;
    font-weight: 800;
}

.hero-card{
    height: 432px;
    max-width: 752px;
    background: #FFFFFF;
    box-shadow: 10px 10px 30px 20px rgba(0, 66, 95, 0.05);
    border-radius: 8px;
    margin: 0;
    z-index: 2;
    padding: 56px;
}

.mgt-card{
    background: #FFFFFF;
box-shadow: 10px 10px 30px 20px rgba(0, 66, 95, 0.05);
border-radius: 8px;
 flex-direction: row; 
 padding-top: 71px;
 padding-bottom: 117px;
 row-gap: 48px;
 justify-content: space-around;
}

.contract-mgt.forcourt-feature-container{
    margin-top: 45px;
    padding-right: 10%;
    padding-left: 10%;
    margin-bottom: 151px;
}

.quick-energy .hero-card{
    max-width: unset;
    padding-right: 0px;
    height: unset;
}

.hero-card.self{
    margin-top: 50px;
    height: unset;
}

.hero-card.wifi{
    margin-top: 25px;
    max-height: 330px;
    /* max-width: 648px; */
}

.hero-img.wifi{
    background-image: url(../../assets/images/wifi.png);
    margin-top: 0px;
    height: 385px;
    max-width: 580px;
}

.hero-img.monitor{
  background-image: url(../../assets/images/fuel_monitor.png);
  min-height: 535px;
}

.hero-card.monitor{
    min-height: 620px;
    margin-top: 31px;
    margin-left: 0px;
    margin-right: 20px;
}

.hero-card p {
    /* word-spacing: -3px; */
    color: #353F50;
    font-size: 16px;
}


.card-fort-header{
    font-size: 20px;
    font-weight: 800;
    color: #111111;
    margin-bottom: 3px;
    font-family: 'Averta', sans-serif
}

.card-sub-header{
    font-family: 'Averta', sans-serif;
    font-size: 16px;
    font-weight: 800;
    color: #353F50;
    padding-bottom: 0px;
    margin-bottom: 2px;
}

ul.card-list{
    color: #353F50;
    font-family: 'Averta', sans-serif;
    font-size: 16px;
    margin-left: 25px;
}

ul.card-list li{
  list-style: disc outside none !important;
  color: #353F50;
  font-size: 16px;
  font-family: 'Averta', sans-serif;
  padding-top: 10px;
  /* word-spacing: -5px; */
}

.hero-img{
    max-width: 413px;
    height: 449px;
    background: url(../../assets/images/payment_success.png);
    border-radius: 6px;
    margin: 0px;
    margin-top: 50px;
    margin-left: -25px;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
}

.tanker-img{
    float: right;
    margin: -25px;
    margin-top: -79px;
}

.quick-energy .hero-img{
    background: unset;
    height: unset;
}

.quick-energy .hero-img img{
    position: absolute;
    top: -77px;
    right: -20px;
}

.hero-img.self{
  background-image: url(../../assets/images/self_service.png);
  margin-left: 0px;
  margin-top: 0px;
  margin-right: -25px;
}

.forcourt-feature-container{
    margin-top: 102px;
    padding-left: 13%;
    padding-right: 13%;
}

.forcourt-feature-container.quick-energy{
    padding-left: 11.4%;
    padding-right: 11.4%;
    margin-bottom: 151px;
}

.check-list-container{
    padding-left: 10%;
    padding-right: 10%;
    justify-content: center;
    column-gap: 5%;
    margin-bottom: 360px;
}

.check-list-container .lower{
    column-gap: 7px;
}

.checklist-item, .checklist-item p{
    text-align: center;
}

.checklist-item img{
    height: 26px;
    width: 26px;
}

.checklist-item h5{
    font-size: 16px;
    font-weight: 800;
    color: #111;
    /* word-spacing: -5px; */
    white-space: nowrap;
    margin-top: 24px;
}

.checklist-item{
    color: #353F50;
    font-size: 16px;
    /* word-spacing: -5px; */
    padding: 0px;
    max-width: 30%;
}

.landing-button{
    width: 201px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #E1E6ED;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #F9FBFC;
    text-decoration: none;
    font-family: 'Averta', sans-serif !important;
}

.landing-button.pretextIcon{
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
}

.landing-button.pretextIcon img{
    margin-right: 10px;
}

.deep-blue{
    background-color: #0275D8;;
}

.landing-button:hover{
    color: #F9FBFC;
    text-decoration: none;
}

.hero-button{
    margin-top: 44px;
    font-weight: 400;
}

.land-section{
  /* display: flex;
  justify-content: center; */
  
}

.caption-section{
    text-align: center;
    width: 50%;
}
.product-nav-section{
    /* padding: 0px 10%; */
    padding-bottom: 151px
}
.product-card{
    /* height: 300px; */
    border-radius: 8px;
    background-color: white;
    
    margin: 23px;
    border: none;
    width: 354px;
    max-width: 354px;
    text-align: center;
}

.product-card:hover{
  box-shadow: 10px 10px 30px 20px rgba(0, 66, 95, 0.05);
}

.product-card:hover .card-link{
  color: #307399
}

.empty-product-nav h2{
    font-size: 40px;
    font-weight: 800;
    margin-top: 40px;
    color: #111111;
}
.empty-product-nav p{
    font-size: 16px;
    color: #111111;
}

.icon-circle{
    height: 120px;
    background: #00425F;
    border-radius: 65px;
    width: 120px;
    position: absolute;
    right: 30%;
    top: -20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-inner{
    text-align: center;
    margin: 25px;
    margin-top: 105px;
}

.card-icon{
  height: 42px;
  width: 42px;
  margin-bottom: 24px;
}

.card-caption{
    font-weight: 800;
    font-size: 20px;
    color: #307399;
    font-family: 'Averta', sans-serif !important;
}

.lan-txt{
    font-size: 16px;
    font-weight: 400;
    color: #111111;
}

.card-link{
    color :#479FC8;
    text-decoration: none;
    font-weight: 800;
    font-size: 16px;
}

.card-link:hover{
    color :#307399;
    text-decoration: none;
}

.benefit-section{
    padding: 0px;
    margin: 0px;
}
.benefit-cap{
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    color: #00B8DE;
    font-family: 'Averta', sans-serif !important;
}

.benefit-left{
    padding: 0px;
    margin: 0px;
    background-color: #00425F;
    color: white;
    font-size: 16px;
    font-family: 'Averta', sans-serif !important;
}

.benefit-left > div{
    margin: 104px;
    margin-bottom: 80px;
}

.contractmgt-benefit.quick-energy-benefit .benefit-left > div{
    margin-top: 90px;
    margin-bottom: 114px;
}

.benefit-left ul li {
    list-style: none;
    margin: 16px 0px;
    display: flex;
    align-items: center;
  }

.contractmgt-benefit .benefit-left ul li {
    max-width: 80%;
}

.benefit-left p{
    display: inline-block;
    max-width: 60%;
    margin: 0px;
    padding: 0px;
    color: white;
}

.orange-circle{
    width: 14px;
height: 14px;
background: #EE312A;
border-radius: 65px;
display: inline-block;
margin-right: 24px;
min-width: 14px;
}

.benefit-right{
    padding: 0px;
    margin: 0px;
    background-image: url('../../assets/images/banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100%;
}

.quick-energy-benefit{
    margin-bottom: 50px;
}

.quick-energy-benefit .benefit-right{
    background-image: url('../../assets/images/drivers.png');
}

.contractmgt-benefit.quick-energy-benefit .benefit-right{
    background-image: url('../../assets/images/pump_tank.png');
}

.quick-energy-benefit .benefit-left > div{
 margin-top: 39px;
 margin-left: 10%;
 margin-bottom: 87px;
 margin-right: 10%;
}

.sol-cap{
    font-family: 'Averta', sans-serif !important;
    font-size: 32px;
    font-weight: 800;
}
.h.sol-cap{
    text-align: center;
    color: #111;
    margin-bottom: 84px;
}

.sol-section{
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #111111;
    margin-bottom: 56px;
}

.sol-section> span{
    margin-top: 23px;
    max-width: 55%;
    /* word-spacing: -4px; */
}

.product-nav{
  padding-top: 130px;
}

.product-section{
    padding: 80px 0px 70px 104px;
    margin: 0px;
}

.product-section  .icon-circle{
  width: 50px;
  height: 50px;
  background-color: #EE312A;
  position: unset;
}

.product-section  .icon-circle.cm{
    background-color: #00B8DE;
  }

  .product-section  .icon-circle.qe{
    background-color: #00425F;
  }

.product-section .icon-circle img{
    height: 25px;
    width: 25px;
}
.product-section.contract{
    background-color: #F3F5F6;
}

.product-name{
 font-family: 'Averta', sans-serif !important;
  font-weight: 800;
  font-size: 32px;
  color: #000;
  margin: 25px 0px 30px 0px;
  white-space: nowrap;
  /* word-spacing: -10px; */
}

.product-description{
  font-family: 'Averta', sans-serif !important;
  font-weight: 400;
  font-size: 16px;
  color: #4E5A6C;
  max-width: 90%;
}

.prod-sec-right{
    margin-left: -3% !important;
}

.landing-button.icon-button{
    font-family: 'Averta', sans-serif !important;
    font-size: 16px;
    font-weight: 800;
    color: #353F50 !important;
}

.feature-list{
   
}

.feature-list-item{
    color: #111111;
    font-size: 16px;
    font-weight: 800;
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    font-family: 'Averta', sans-serif !important;
}

.feature-list-item> img{
    padding-right: 25px;
}

.feature-list-item > span{
  max-width: 58%;
  display: inline-block;
}
.quick-energy.pre-footer{
    margin-top: 0px;
}

.pre-footer{
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-top: 70px;
}

.pre-footer-card .btn-ctn{
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
}

.pre-footer-ctn{
    background-color: #00425F;
    color: white;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 70px;
    min-height: 583px;
}

.pre-footer-ctn h2{
    margin-top: 46px;
    font-size: 32px;
    font-weight: 800;
}

.pre-footer-ctn p{
  font-size: 16px;
  margin-top: 18px;
  color: white;
}

.pre-footer-lower{
    background-color: transparent;
    height: 240px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.pre-footer-card{
    border-radius: 21px;
    background-color: white;
    width: 80%;
    height: 230px;
    background-image: url(../../assets/images/curvy_marks.svg);
    background-position: left;
    background-repeat: no-repeat;
    /* position: absolute; */
    border: none;
    margin-top: -93px;
    padding: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.pre-footer-card h3{
    font-weight: 800;
    font-size: 20px;
    color: #111111;
}
.pre-footer-card span{
    float: left;
    color: #111111;
    font-size: 16px;
    /* word-spacing: -5px; */
}

.footer-container{
    background-color: white;
    padding: 100px 150px;
}

.footer-link{
    font-family: 'Averta', sans-serif !important;
    font-size: 18px;
    font-weight: 800;
    color: #353F50;
    white-space: nowrap;
}

.footer-line{
    border: solid 0.5px #E1E6ED;
    margin: 44px 0px 20px 0px;
}

.footer-base{
    display: flex;
    justify-content: space-between;
}
.social-icons{
    min-width: 30%;
}

.sol-section.forcourt{
    margin-top: 200px;
}

.footer-logo{
    height: 36px;
    margin-left: 2px;
}
.d-pl{
    padding-left: 3rem;
}

.lead-succes-modal{
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #353F50;
  font-family: 'Averta', sans-serif;
  height: 300px;
}

.lead-succes-modal h2{
 font-size: 18px;
 font-weight: 800;
 /* word-spacing: -5px; */
 margin-bottom: 12px;
}

.lead-succes-modal img{
  height: 46px;
  margin-top: 40px;
  margin-bottom: 37px;
}

.lead-form-container{
   /* width: ; */
}

.lead-modal-container{
   padding: 10px 70px 45px 70px
}

.lead-modal-container legend{
  font-weight: 800;
  font-size: 12px;
  color: #353F50;
  font-family: 'Averta', sans-serif;
  padding-bottom: 13px;
  /* word-spacing: -3px; */
}

.lead-form-modal{
    color: #353F50;
    min-width: 400px;
    width: 60% !important;
}

.lead-title{
  /* word-spacing: -5px; */
  font-size: 18px;
  font-weight: 800;
  font-family: 'Averta', sans-serif;
}

.lead-caption{
    font-size: 14px;
    text-align: center;
    font-family: 'Averta', sans-serif;
    /* word-spacing: -5px; */
    /* max-width: 257px; */
}

.landing-input{
    /* padding-right: 20px !important; */
}

.landing-input label{
    color: #353F50;
    font-size: 12px;
    font-weight: 800;
    font-family: 'Averta', sans-serif;
    /* word-spacing: -3px; */
}

.form-check.landing-input label{
    font-size: 14px;
    font-weight: 400;
}

.landing-input input, .landing-input select{
    border: none;
    padding: 12px;
    border-radius: 4px;
    background-color: #F3F5F6;
    color: #353F50;
    height: 48px;
    font-family: 'Averta', sans-serif;
    /* word-spacing: -3px; */
}

.landing-input .ant-select-selection{
    padding: 6px;
}

.landing-input select{
    width: 100%;
    background: url(../../assets/images/drop.svg) no-repeat right #F3F5F6;
    -webkit-appearance: none;
    background-position-x: 90%;
    font-family: 'Averta', sans-serif;
}

.landing-input input::placeholder{
  color: rgba(53, 63, 80, 0.2);
  /* opacity: 0.2; */
}
.lead-form-btn-ctn{
    margin-top: 72px;
    display: flex;
    justify-content: center;
}

.lead-form-button{
    width: 92px;
    height: 40px;
    background: #0275D8;
    border-radius: 6px;
    font-weight: 800;
    border: none;
    color: white;
}
.lead-form-button.cancel{
  border: none;
  background: transparent;
  color: #5F738C;
  box-shadow: none;
}

.placeholder{
    color: rgba(53, 63, 80, 0.2);
}

.ndpr-modal .ant-modal-content{
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.ndpr-modal{
    max-width: 652px;
    margin-top: 10%;
    font-variant: normal !important;
    font-feature-settings: normal !important;
    -webkit-font-feature-settings: normal !important;
}

.ndpr-modal .ant-modal-body{
    padding-bottom: 16px;
    padding-top: 42px;
}

.ndpr-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ndpr-buttonctn{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 36px;
}

.ndpr-button{
    width: 174px;
    height: 40px;
    border-radius: 6px;
    color: white !important;
    background: #0275D8;
    font-size: 16px;
    font-weight: 800;
    column-gap: 16px;
}

.ndpr-text-ctn{
    text-align: center;
    max-width: 75%;
    margin-top: 24px;
    padding: 16px;
    padding-bottom: 0px;
}
.ndpr-text-ctn h3{
   font-size: 18px;
   font-weight: 800;
   margin-top: 24px;
   margin-bottom: 12px;
   color:#353F50 !important;
   font-family: 'Averta', sans-serif;
}
.ndpr-text-ctn p{
    text-align: center;
    font-size: 14px;
    color:#353F50 !important;
    font-family: 'Averta', sans-serif;
}

.ndpr-cancel-btn{
    color: #5F738C;
    font-size: 16px;
    font-weight: 800;
    font-family: 'Averta', sans-serif;
}

@media only screen and (max-width: 1000px)  {
    .d-tab{
        display: none;
    }
    .landing-nav{
        padding-left: 0px;
        padding-right: 0px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    }
 
    .hero-caption{
        font-size: 24px;
        font-weight: 700;
    }

    .sub-hero-caption{
        font-size: 14px;
    }

    .sub-hero-button{
        font-weight: 400;
    }

    .sub-hero{
        padding: 32px;
        text-align: center;
    }

    .sitemap-container{
      padding-left: 24px;
    }

    .forcourt-feature-container{
        margin-top: 67px;
    }
    .hero-card{
        height: unset;
        order: 2;
        margin-top: -22px;
        margin-left: -20px;
        padding-left: 23px;
        padding-right: 23px;
    }

    .card-fort-header{
        font-size: 20px;
        font-weight: 700;
    }

    .hero-img{
        margin-right: -50px;
        max-width: unset;
        height: 350px;
        margin-top: 0px;
    }

    .hero-card.self{
        margin-top: -25px;
         margin-right: -50px;
    }
    
    .hero-img.self{
        margin-left: -40px;
    }

    .hero-card.wifi{
        margin-top: -25px;
        /* margin-right: -50px; */
        max-height: 400px;
    }

    .hero-img.wifi{
        /* margin-right: unset; */
        max-width: unset;
    }
    .hero-img.monitor{
        margin-right: unset;
    }
    .hero-card.monitor{
        margin-top: -25px;
         margin-right: -25px;
    }

    .checklist-item{
        max-width: unset;
        margin-bottom: 50px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .checklist-item.last{
        margin-bottom: 0px;
    }
    
    .checklist-item h5{
        white-space: normal;
    }
    .check-list-container{
        margin-bottom: 50px;
        column-gap: unset;
    }
    .sol-section.forcourt{
      margin-top: 33px;
      padding-bottom: 50px;
      padding-top: 49px;
      padding-left: 33px;
      padding-right: 33px;
      height: unset;
      margin-bottom: 80px;
    }
    .sol-section.forcourt .sol-cap{
        /* word-spacing: -3px; */
    }
    .empty-product-nav{
        padding: 50px;
    }
    .empty-product-nav h2{
        font-size: 24px;
        font-weight: 700;
    }
    .product-nav-section{
        padding-bottom: 70px;
    }
    .product-nav-section{
        display: flex;
        justify-content: center;
        padding-bottom: 48px;
    }

    .benefit-right{
        min-height: 400px;
        order: -1;
     }

     .feature-list{
        padding-left: 5px;
        padding-top: 50px;
       }
   
       .prod-sec-right{
           margin-left: 0px !important;
       }

       .prod-sec-right> .feature-list{
        padding-top: 0px !important;
         }

         .pre-footer-card{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;
            row-gap: 30px;
            margin-top: -93px;
         }
         .pre-footer-ctn h2{
             font-size: 24px;
             font-weight: 700;
         }
         .pre-footer-card span span{
            margin-top: 10px;
         display: inline-block;
         text-align: center;
         width: 100%;
         }
         .pre-footer-lower{
             height: 212px;
         }
         .pre-footer-ctn{
             padding: 0px;
             padding-bottom: 142px;
         }

         .pre-footer-ctn .right{
             order: 2;
             padding-right: 10%;
             padding-left: 10%;
         }

         .pre-footer-ctn .left img{
             width: 100%;
             max-width: unset;
         }
    
     .dpl{
         padding-left: 0px;
     }
}

@media only screen and (max-width: 600px){
    .ios-checbox{
        width: 12px !important;
        height: 12px !important;
    }

    .ios-form-check-label{
        margin-top: 5px;
        margin-left: 15px !important;
        vertical-align: -webkit-baseline-middle;
        vertical-align: middle;
        white-space: nowrap;
    }

    .ndpr-cancel-btn{
        font-size: 14px;
    }
    .ndpr-button{
        font-size: 14px;
    }
    .ndpr-modal{
        margin: 15px;
        margin-top: 0%; 
        min-width: unset;
         width: 90% !important;
    }
    .ndpr-modal .ant-modal-body{
        padding-left: 12px;
        padding-right: 12px;
    }
    .ndpr-text-ctn{
        max-width: unset;
    }
    .ndpr-buttonctn{
        justify-content: space-between;
        column-gap: unset;
    }
    .vwrap{
        height: 200px;
    }
     .head-caption {
        box-sizing: border-box;
        width: 100%;
        height: 150px;
      }
      .h-200{
          height: 200px !important;
      }
    .quick-energy.product-nav{
        margin-bottom: 100px;
    }
    .quick-energy .hero-card{
      margin-left: -7px;
    }
    .tanker-img{
     height: 338px;
    margin: unset;
    margin-top: 49px;
    margin-right: -7px;
    }
    .quick-energy-benefit .benefit-left > div{
        margin-bottom: 40px;
    }

    .quick-energy.hero-card{
        max-width: unset;
        padding-right: 0px;
        height: unset;
        margin-left: -7px;
    }
    
    .product-nav{
        padding-top: 0px;
      }
    
      .sol-cap{
          /* word-spacing: -7px !important; */
      }

    .product-name{
          font-size: 26px;
          /* word-spacing: -7px !important; */
    }

    .hero-img{
        /* max-width: 413px; */
        height: 449px;
    }

    .hero-img.wifi{
        background: url(../../assets/images/wifi_mobile.png);
        max-height: 295px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: -17%;
    }
    .quick-energy .product-nav-section{
        margin-top: 0px;
    }
    .quick-energy-benefit{
        margin-bottom: 36px;
    }
    .empty-product-nav{
        padding: 50px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .quick- .hero-card{
        z-index: 1;
    }

    .quick-energy .hero-img{
        order: 1;
        height: 338px;
        z-index: 2;
    }
    .hero-card .order{
        order: 2;/* margin-top: 50px; */
    }

    .forcourt-feature-container.quick-energy{
        margin-bottom: 49px;
    }

    .d-mobile{
      display: none;
    }
    h1{
        /* word-spacing: -10px; */
    }
    
    h2{
        /* word-spacing: -5px; */
    }

    .d-desktop{
        display: unset !important;
    }

    .landing-nav{
        color: #353F50;
        font-family: 'Averta', sans-serif;
    }

    .hero-section{
        height: 602px;
        padding: 35px;
        background: url(../../assets/images/new_hero_mobile.png) no-repeat;
        background-size: cover;
       display: flex;
       justify-content: center;
       align-items: center;
       text-align: center;
        /* height: ; */
       }
       .hero-img.monitor{
        background: url(../../assets/images/fuel_monitor_mobile.png);
        background-repeat: no-repeat;
        max-height: 280px;
        min-height: unset;
       }
    .cap-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .head-caption{
        font-size: 32px;
        font-weight: 700;
    }

    .sub-caption{
        font-size: 14px;
    }
    
    .sol-section{
        height: 166px;
        /* padding: 20px; */
    }

    .sol-cap{
        font-size: 20px;
        font-weight: 700;
        max-width: unset;
        /* word-spacing: -10px; */
    }

    .sol-section> span{
        margin-top: 23px;
        max-width: unset;
        font-size: 16px;
        /* word-spacing: -4px; */
    }
    
    .land-section{
        padding: 30px;
    }
    .land-section .product-nav-section{
        padding-top: 90px;
    }

    .product-nav-section{
        padding: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    /* .product-nav-section> div{
        padding-top: 90px;
        padding-bottom: 0px;
    } */

    .product-card{
        margin-top: 80px;
        box-shadow: unset;
    }

    .benefit-right{
        order: -1;
    }

    .benefit-left> div{
        margin: 33px;
     }

     .benefit-cap{
        font-size: 20px;
     }

     .benefit-left p{
         font-size: 14px;
         max-width: unset;
     }

     .benefit-left ul li{
         font-size: 14px;
     }

     .product-section{
        padding: 70px 20px 70px 32px;
        margin: 0px;
    }

    .bg-sm-white{
        background-color: white;
    }

    /* .feature-list{
     padding-left: 5px;
     padding-top: 50px;
    }

    .prod-sec-right{
        margin-left: 0px;
    } */
   .footer-container{
       padding: 55px;
   }

   .footer-link{
       display: block;
   }

   .footer-line{
    margin: 38px 0px 20px 0px;
   }

   .footer-base{
       display: block;
   }

   .footer-base > span{
       flex-direction: column;
   }

   .social-icons{
       order: 2;
       min-width: 100%;
       margin-top: 53px;
       margin-bottom: 50px;
       display: flex;
       justify-content: space-between;
       flex-direction: row !important;
   }

   .pre-footer-card .btn-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
   }
   .quick-energy.pre-footer .pre-footer-card{
       height: unset;
   }

   .mgt-card{
    background: transparent;
    box-shadow: unset;
    border: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
    row-gap: 5px;
    padding-top: 0px;
   }

   .contract-mgt.forcourt-feature-container{
       margin-bottom: 51px;
   }

   .mg-txt-section{
    display: block !important;
    padding: 33px;
    padding-top: 49px;
    padding-bottom: 95px;
    margin-bottom: 72px !important;
   }
}