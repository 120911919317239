.nav-link.land-nav{
    font-size: 16px;
    font-weight: 600;
    color: #353F50 !important;
    font-family: 'Averta', sans-serif;
    white-space: nowrap;
}
.landing-nav .navbar-nav{
 padding-left: 0px;
 justify-content: flex-end;
 column-gap: 4%;
}

.landing-nav .navbar-brand{
 margin-right: 15%;
}
  .nav-logo{
      height: 28px;
  }

  .energy-logo{
      height: 43px;
  }

  .nav-link.land-nav.button{
    height: 48px;
    font-size: 14px;
    width: 180px;
  }

  .nav-link.land-nav.button.sales{
    background-color: #479FC8;
    color: white !important;
  }

  @media only screen and (max-width: 992px)  {
    .nav-link.land-nav{
      margin-bottom: 36px;
    }
    .landing-nav .navbar-nav{
      justify-content: unset;
    }
    .nav-link.land-nav.button{
      margin-bottom: 36px;
    }

  .landing-nav .navbar-toggler{
      border: none;
      background-color: transparent;
      padding-right: 19px;
  }

  .landing-nav .navbar-brand{
      padding-left: 30px;
  }

  .landing-nav .navbar-toggler.collapsed{
      padding-right: 25px;
  }
  
  .landing-nav .navbar-toggler[aria-expanded=true] .navbar-toggler-icon{
      background-image: url(../../../assets/images/menu_cancel.svg);
      height: 20px;
  }

  .landing-nav .navbar-toggler .navbar-toggler-icon, .landing-nav .navbar-toggler.collapsed .navbar-toggler-icon{
      background-image: url(../../../assets/images/mobile_menu.svg);
      height: 32px;
  }
  
  .landing-nav .navbar-collapse.show{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 34px;
    gap: 10px;
    background-color: white;
    margin-top: 2.1%;
    min-height: 340px
  }

  .energy-logo{
    height: 28px;
    margin-left: -5px;
    }
  }