.tc_container{
    width: 100%;
    background-color: white;
    margin: 1rem 0;

    padding-left: 127px;

}

.top-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    h3{
       margin-bottom: 1rem; 
    }
}
.section__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
    /* color: black; */

    li {
        list-style: decimal-leading-zero;
        margin: 0.2rem 0;
    }
}

.section__wrapper-header{
color: black;
}
.section__wrapper-subHeader{
    margin: 0.5rem 0;
}

@media only screen and (max-width: 1000px){
    .sitemap-container {
        padding-left: 24px;
    }
}